require('./bootstrap');

// LIBRERIA PER I CAROUSEL
// Tiny Slider
import 'tiny-slider/dist/tiny-slider.css';
import { tns } from "tiny-slider/src/tiny-slider";
window.tns = tns;

// LIBRERIA PER IL PARALLASSE:
//const simpleParallax = require('simple-parallax-js'); <-- Common JS
import { simpleParallax } from 'simple-parallax-js';        // <-- Nuovo JS consigliato da Giovanni
window.simpleParallax = simpleParallax;


//TyppyJs CSS
//import { animateFill } from 'tippy.js';   // Viene importato in bootstrap.js
import 'tippy.js/dist/tippy.css';
import 'tippy.js/dist/backdrop.css';
import 'tippy.js/animations/shift-away.css';
import 'tippy.js/animations/scale.css';
import 'tippy.js/animations/scale-subtle.css';
import 'tippy.js/animations/scale-extreme.css';
import 'tippy.js/themes/material.css';

// AOS CSS Animator
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();

// Bootstrap5 Date Picker: https://mymth.github.io/vanillajs-datepicker/#/
import { Datepicker } from 'vanillajs-datepicker';
import { DateRangePicker } from 'vanillajs-datepicker';
window.Datepicker = Datepicker;
window.DateRangePicker = DateRangePicker;

// Masonary
import { masonry } from 'masonry-layout';
window.masonry = masonry;


// FUNZIONI SPECIALI E SPECIFICHE DEL SITO
